import React,{useEffect,useState} from 'react'
import style from './style3.module.css';
import { FiX } from "react-icons/fi";
import img from "../../assets/popup.jpg"

export default function Hero3() {
    const [showPopup, setShowPopup] = useState(false);

    useEffect(() => {
      setShowPopup(true); // Show popup on page load
      
    }, []);
  return (
    <div  className={style.bgimage} >
        <div className={style.bgImageBox}>
            
            <h2 className={style.headermintext}>EMPOWERING CONSUMER</h2>
            <h1 className={style.headertext}>ENFORCING <br></br>RIGHTS</h1>

        </div>
        {/* {showPopup && (
        <div className={[style.popupStyle,"mx-auto d-block"]}>
          <div className={style.popupContentStyle}>
            <h2>Welcome!</h2>
            <p>This is a pop-up message.</p>
            <button onClick={() => setShowPopup(false)}>Close</button>
          </div>
        </div>
      )} */}

{/* {showPopup && (
    <div style={{position:"absolute",width:"100%",height:"100vh",display:"flex",justifyContent:"center",alignItems:"center",background:"rgba(0,0,0,0.5)"}}>
      <div className={style.popBox}>
        <div style={{width:"100%",height:50,display:"flex",alignItems:"center",justifyContent:"end"}}>
        <div onClick={() => setShowPopup(false)} style={{width:40,height:40,marginRight:10,backgroundColor:"#ddd",display:"flex",justifyContent:"center",alignItems:"center",borderRadius:100}}>
        <FiX size={20} color="#000"/>
        </div>
        </div>

        
        <h3 style={{textAlign:"center",marginTop:60,color:"#fff",fontSize:20}}>CONSUMER RIGHTS ORGANISATION </h3>
        <h1 className={style.heading}>You`re  Invited On Our 15th <br></br>
        Foundation day & National Meet
        </h1>

        <h5 className={style.eventDate}>7 APRIL , 2025</h5>
        <a href='https://docs.google.com/forms/d/e/1FAIpQLSf-UOMvHAcEzPcgpJHgrhp9sJsmIQCTPxLVjamPZ9INEC3khg/viewform?usp=sharing' style={{backgroundColor:"transparent",color:"#fff",fontSize:18,textAlign:"center"}} className='mx-auto d-block'>Click  For Registration</a>

      </div>
      </div>
  )} */}
    </div>
  )
}
